




















import { Post, Publication } from '@kessel/core'
import { PropType, computed, defineComponent, onMounted, toRefs, useContext, useRoute, useRouter } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { usePost } from '~/stores/post_v2'
import { useUser } from '~/stores/user'

export default defineComponent({
  props: {
    post: { type: Object as PropType<Post>, default: null },
    publication: { type: Object as PropType<Publication>, required: true },
  },
  setup(props, { root: { $buefy } }) {
    const { post } = toRefs(props)
    const { i18n, localePath } = useContext()
    const route = useRoute()
    const router = useRouter()
    const { toggleLikePost } = usePost()
    const { isLiked } = storeToRefs(usePost())
    const { isLogged } = storeToRefs(useUser())

    const iLikeIt = computed(() => isLiked.value)
    const isLikeQuery = computed(() => !!route.value.query.like && !iLikeIt.value)

    const toggleLike = async () => {
      if (!isLogged.value) {
        await router.replace({ query: { ...route.value.query, like: 'true' } })
        return router.push(localePath({
          name: 'login',
          query: {
            redirect: route.value.fullPath,
          },
        }))
      }
      await toggleLikePost(post.value.id)
    }

    onMounted(async () => {
      if (!isLogged.value && isLikeQuery.value) {
        return router.push(localePath({
          name: 'login',
          query: {
            redirect: route.value.fullPath,
          },
        }))
      }
      if (isLikeQuery.value) {
        await toggleLikePost(post.value.id)
        await router.push({ query: { ...route.value.query, like: undefined } })
        $buefy.toast.open({
          message: i18n.t('SUCCESS_LIKE') as string,
          type: 'is-success',
          duration: 5000,
        })
      }
    })

    return {
      iLikeIt,
      isLogged,
      toggleLike,
    }
  },
})
