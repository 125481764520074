import { stringify } from 'querystring'
import { Post, Publication } from '@kessel/core'
import copy from 'copy-to-clipboard'

const popup = (link: string) => {
  const width = 550
  const height = 650
  const left = screen.width / 2 - width / 2
  const top = screen.height / 2 - height / 2
  window.open(link, 'sharer', `top=${top},left=${left},toolbar=0,status=0,width=${width},height=${height}`)
}

const copyLink = (link: string, callback = () => {}) => {
  copy(link)
  callback()
}

const links = (url: string, publication: Publication, post?: Post, copyCallBack?: () => void) => {
  const title = `${publication?.title}${post?.title ? ` - ${post?.title}` : ''}`

  const encodedParams = stringify({
    text: title + ' - via @kesselfr',
    url,
    u: url,
    'p[title]': title,
  })

  return [
    { to: `https://twitter.com/share?${encodedParams}`, icon: 'twitter', target: 'new', title: 'Twitter' },
    { to: `https://www.facebook.com/sharer.php?${encodedParams}`, icon: 'facebook', click: popup, title: 'Facebook' },
    { to: `https://www.linkedin.com/sharing/share-offsite/?${encodedParams}`, icon: 'linkedin', click: popup, title: 'LinkedIn' },
    { to: url, click: (url: string) => copyLink(url, copyCallBack), icon: 'link', title: 'Lien direct' },
  ]
}

export { links, popup, copyLink }
