







































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useOffer } from '~/stores/offer_v2'
import { usePost } from '~/stores/post_v2'
import { usePublication } from '~/stores/publication_v2'
import { useRights } from '~/stores/right'
import { generateLinear } from '~/utils/color'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  props: {
    likeBtn: { type: Boolean, default: false },
  },
  setup(props) {
    const route = useRoute()
    const { parseHostname } = useDomain()
    const { $config: { authorInscriptionDomain } } = useContext()
    const { likeBtn } = toRefs(props)
    const { publication } = storeToRefs(usePublication())
    const { paymentEnabled } = storeToRefs(useOffer())
    const { post } = storeToRefs(usePost())
    const { subscriptionError, userCanSubscribe, subscriptionLevelsLabel } = storeToRefs(useRights())
    const stickyEl = ref<HTMLElement>()
    const sticking = ref(false)
    const linearColor = computed(() => generateLinear(publication.value!.color_primary))

    const inscriptionUrl = computed(() => {
      const appDomainName = parseHostname().subdomain
      return `${authorInscriptionDomain}/${appDomainName}`
    })

    const observer = ref<IntersectionObserver | Object>({})

    const canShowLike = computed(() => likeBtn.value && !!route.value?.params?.id)

    onMounted(() => {
      observer.value = new IntersectionObserver(
        ([entry]) => {
          sticking.value = !entry.isIntersecting
        },
        {
          threshold: 0.5,
        }
      )
      if (stickyEl.value && observer.value instanceof IntersectionObserver) {
        observer.value.observe(stickyEl.value)
      }
    })

    onUnmounted(() => {
      if (observer.value instanceof IntersectionObserver) {
        observer.value?.disconnect()
      }
    })
    return {
      canShowLike,
      publication,
      post,
      isPost: computed(() => !!post.value),
      subscriptionEnabled: paymentEnabled,
      userCanSubscribe: computed(() => userCanSubscribe.value()),
      subscriptionLevelsLabel: computed(() => subscriptionLevelsLabel.value()),
      subscriptionError: computed(() => subscriptionError.value()),
      stickyEl,
      sticking,
      linearColor,
      inscriptionUrl,
    }
  },
})
