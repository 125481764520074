







































import { Owner, Post, Publication } from '@kessel/core'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    owner: { type: Object as PropType<Owner>, required: true },
    publication: { type: Object as PropType<Publication>, required: false, default: null },
    post: { type: Object as PropType<Post>, required: false, default: null },
  },
})
