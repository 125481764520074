import { render, staticRenderFns } from "./PostLikeButton.vue?vue&type=template&id=e8c5bdae&scoped=true&"
import script from "./PostLikeButton.vue?vue&type=script&lang=ts&"
export * from "./PostLikeButton.vue?vue&type=script&lang=ts&"
import style0 from "./PostLikeButton.vue?vue&type=style&index=0&id=e8c5bdae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8c5bdae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Like: require('/tmp/build_84effd69/apps/web/components/buttons/Like.vue').default,KTooltip: require('/tmp/build_84effd69/apps/web/components/KTooltip.vue').default})
