




















import { Post, Publication } from '@kessel/core'
import { PropType, computed, defineComponent, toRefs, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useDomain } from '~/stores/domain'
import { usePublication } from '~/stores/publication_v2'
import { links } from '~/utils/share'

export default defineComponent({
  props: {
    post: {
      type: Object as PropType<Post>,
      default: null,
    },
  },
  setup(props, { root: { $buefy } }) {
    const { post } = toRefs(props)
    const { publication } = storeToRefs(usePublication())
    const { pathToPublication, pathToPost } = useDomain()
    const { i18n } = useContext()

    const url = computed(() => post?.value ? pathToPost(post.value) : pathToPublication(publication.value) as string)

    const shareLinks = computed(() => links(
      url.value,
      publication.value as Publication,
      post.value,
      () => $buefy.toast.open(i18n.t('SHARE_COPIED_LINK').toString())
    ))

    return {
      shareLinks,
    }
  },
})
